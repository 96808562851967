import styled, { keyframes } from "styled-components";
import { Palette } from "../../theme/Palette";
interface IStyleProps {
    readonly visible?: boolean;
}

export const TooltipWrapper = styled.div`
    position: relative;
    width: 24px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const TooltipContent = styled("div")<IStyleProps>`
    animation: ${({ visible = true }) => (visible ? fadeIn : fadeOut)} 150ms linear;
    display: ${({ visible = true }) => (visible ? "block" : "none")};
    position: absolute;
    background-color: ${Palette.Primary10};
    color: ${Palette.Primary100};
    padding: 8px;
    z-index: 1;
    border-radius: 2px;
    width: 200px;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    margin-left: 8px;

    :after {
        content: "";
        position: absolute;

        left: 0%;
        margin-left: -16px;

        top: 50%;
        transform: translateY(-50%);

        border: 8px solid ${Palette.Primary10};
        border-color: transparent ${Palette.Primary10} transparent transparent;
    }
`;
