import axios, { AxiosError } from "axios";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../../utilities/Constants";
export interface ISubmission {
    readonly _id: string;
    readonly created: string;
    readonly completed: string;
    readonly image: string;
    readonly alias: string;
}

export type ISubmissionResponse = Array<ISubmission>;

const getSubmissionsByTeamId = async (token: string | null, teamId?: string) => {
    const config = getAxiosRequestConfig(token);
    const uri = teamId === undefined ? `${Constants.ApiRoot}/submission` : `${Constants.ApiRoot}/submission/${teamId}`;
    try {
        const { data } = await axios.get<ISubmissionResponse>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return undefined;
        }
        throw error;
    }
};

export const useSubmissions = (teamId?: string) => {
    const { token } = useContext(AuthContext);

    return useQuery(["submissionsByTeamId", teamId], () => getSubmissionsByTeamId(token, teamId), {});
};
