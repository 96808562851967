import * as React from "react";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    readonly fallback?: JSX.Element;
}

export const SSRSafeSuspense: React.FC<React.PropsWithChildren<IProps>> = ({ children, fallback }) => {
    const [hasMounted, setHasMounted] = useState(false);
    const [t] = useTranslation();
    useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        return null;
    }
    return <Suspense fallback={fallback ?? <div>{t("loading")}</div>}>{children}</Suspense>;
};
