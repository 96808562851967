import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, AlertType, IAlert } from "../../../components/Alerts/AlertsContext";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { Constants } from "../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { telemetry } from "../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../utilities/Telemetry/TelemetryEvent";

interface IPostSubmissionResponse {
    readonly created: string;
    readonly image: string;
}

interface ISubmissionBody {
    readonly image: string;
}

const getMutationQuery = (token: string | null, image: string) => {
    const postRequest = async (): Promise<IPostSubmissionResponse> => {
        const config = getAxiosRequestConfig(token);
        const body: ISubmissionBody = { image };
        const request = axios.post(`${Constants.ApiRoot}/submission`, body, config);

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const usePostSubmission = (image: string, onSuccess: () => void) => {
    const { token } = useContext(AuthContext);
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(token, image), {
        onSuccess: (_data) => {
            telemetry.Log(TelemetryEvent.SubmitAgentSuccess, null);
            queryClient.invalidateQueries(["submissionsByTeamId"]);
            onSuccess();
        },
        onError: (error, _variables, _context) => {
            console.log(error);

            const e = error as AxiosError;
            const submitError = JSON.stringify(e.response?.data);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: 5000,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.SubmitAgentError, submitError);
        },
    });

    return { mutation };
};
