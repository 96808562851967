import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { H2 } from "../../../../components/H2/H2";
import { usePostSubmission } from "../../../../hooks/api/submission/usePostSubmission";
import { useSubmissions } from "../../../../hooks/api/submission/useSubmissions";
import { useTeam } from "../../../../hooks/api/team/useTeam";
import { telemetry } from "../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../utilities/Telemetry/TelemetryEvent";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { Form } from "../../../Form/Form";
import { H3 } from "../../../H3/H3";
import { HelpTooltip } from "../../../HelpTooltip/HelpTooltip";
import { LinkButton } from "../../../LinkButton/LinkButton";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { RouterLink } from "../../../RouterLink/RouterLink";
import { Spinner } from "../../../Spinner/Spinner";
import { StateButton } from "../../../StateButton/StateButton";
import { TextInput } from "../../../TextInput/TextInput";
import { SubmissionList } from "./SubmissionList/SubmissionList";
import { HeadingBlock, Root } from "./Submissions.styles";
import { useTicket } from "../../../../hooks/api/ticket/useTicket";
import { EventCode } from "@coderone/library";
import { BuyTicketButton } from "../../../BuyTicketButton/BuyTicketButton";
import { Constants } from "../../../../utilities/Constants";
import { TelemetryLink } from "../../../TelemetryLink/TelemetryLink";

export const SubmissionsContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const isSubmissionsOpen = true;
    const [t] = useTranslation();
    const { isLoading: isTeamLoading, data: teamData } = useTeam();
    const { isLoading: isSubmissionLoading, data: submissionData } = useSubmissions();
    const [image, setImage] = useState("");
    const bomberlandTicket = useTicket(EventCode.BomberlandV4);

    const isMember = useMemo(() => {
        return bomberlandTicket.data !== null;
    }, [bomberlandTicket]);

    const onImageChanged = useCallback(
        (_: unknown, value: string) => {
            setImage(value);
        },
        [setImage]
    );

    const isImageValid = useMemo(() => {
        return image.length > 0;
    }, [image]);

    const onMutateSuccess = useCallback(() => {
        setImage("");
    }, [setImage]);
    const { mutation } = usePostSubmission(image, onMutateSuccess);
    const { mutate } = mutation;

    const onSubmitAgent: React.FormEventHandler<HTMLFormElement> = useCallback(
        async (e) => {
            e.preventDefault();
            mutate();
            telemetry.Log(TelemetryEvent.SubmitAgentInit, null);
        },
        [mutate]
    );

    const isLoading = useMemo(() => {
        return isTeamLoading || isSubmissionLoading || status === "loading";
    }, [isTeamLoading, isSubmissionLoading, status]);

    if (!isSubmissionsOpen) {
        return (
            <Root>
                <ContentCard justifyItems="center" textAlign="center">
                    <span>
                        <Trans key="submissionsPage.submissionsNotOpen">
                            Submissions are currently closed. Please join our <TelemetryLink href={Constants.DiscordLink} target="_blank">Discord</TelemetryLink> for announcements.
                        </Trans>
                    </span>
                </ContentCard>
            </Root>
        )
    }

    if (!isMember) {
        return (
            <Root>
                <ContentCard justifyItems="center" textAlign="center">
                    {t("submissionsPage.noEntryTicket")}
                    <BuyTicketButton />
                </ContentCard>
            </Root>
        )
    }

    if (isLoading) {
        return <Spinner />;
    }

    if (teamData) {
        return (
            <Root>
                <ContentCard>
                    <HeadingBlock>
                        <H2>{t("mySubmissions")}</H2>
                        <HelpTooltip href={CoderOneRoute.DocumentationSubmissionInstructions} label={t("howDoISubmit")} />
                        <H3>{t("latestImage")}</H3>
                    </HeadingBlock>
                    <SubmissionList submissions={submissionData} />
                    <Form onSubmit={onSubmitAgent}>
                        <TextInput
                            placeholder={"docker.io/my-dockerhub-username/my-repo-name"}
                            value={image}
                            label={t("uploadImage")}
                            onChange={onImageChanged}
                        />
                        <StateButton type="submit" disabled={isImageValid === false} aria-label={t("submitAgent")}>
                            {t("submitAgent")}
                        </StateButton>
                    </Form>
                </ContentCard>
            </Root>
        );
    }

    return (
        <Root>
            <ContentCard>
                <HeadingBlock>
                    <H2>{t("mySubmissions")}</H2>
                    <HelpTooltip href={CoderOneRoute.DocumentationSubmissionInstructions} label={t("howDoISubmit")} />
                </HeadingBlock>
                {t("joinTeamBeforeCanSubmit")}
                <RouterLink href={CoderOneRoute.Team}>
                    <LinkButton as="div">{t("joinTeam")}</LinkButton>
                </RouterLink>
            </ContentCard>
        </Root>
    );
};
