import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const Label = styled.span`
    font-weight: 600;
`;

export const DataWrapper = styled.div``;

export const HeadingBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`;
