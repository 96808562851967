import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ISubmission } from "../../../../../hooks/api/submission/useSubmissions";
import { getRelativeTime } from "../../../../../utilities/getRelativeTime";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { DataWrapper, Label } from "../Submissions.styles";

interface IProps {
    readonly submissions: Array<ISubmission> | undefined;
}

export const SubmissionList: React.FC<React.PropsWithChildren<IProps>> = ({ submissions }) => {
    const [t] = useTranslation();
    if (submissions === undefined || submissions.length <= 0) {
        return <React.Fragment>{t("submissionsPage.noSubmissions")}</React.Fragment>;
    }

    return (
        <React.Fragment>
            <ul>
                {submissions.map((submission) => {
                    const { _id, image, created, alias } = submission;
                    return (
                        <li key={_id}>
                            <DataWrapper>
                                <Trans key="submissionsPage.id">
                                    <Label>id: </Label>
                                    {{ _id }}
                                </Trans>
                            </DataWrapper>
                            <DataWrapper>
                                <Trans key="submissionsPage.alias">
                                    <Label>Alias: </Label>
                                    {{ alias }}
                                </Trans>
                            </DataWrapper>
                            <DataWrapper>
                                <Trans key="submissionsPage.submittedImage">
                                    <Label>Image: </Label>
                                    {{ image }}
                                </Trans>
                            </DataWrapper>
                            <DataWrapper>
                                <Trans key="submissionsPage.submissionTime">
                                    <Label>Submitted: </Label>
                                    {{ time: getRelativeTime(t, created) }}
                                </Trans>
                            </DataWrapper>

                            <RouterLink href={CoderOneRoute.SubmissionRoute(_id)}>{t("submissionsPage.details")}</RouterLink>
                        </li>
                    );
                })}
            </ul>
        </React.Fragment>
    );
};
