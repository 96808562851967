import * as React from "react";
import { useState, useCallback } from "react";
import { TooltipWrapper, TooltipContent } from "./Tooltip.styles";

interface IProps {
    readonly text?: string;
}

export const Tooltip: React.FC<React.PropsWithChildren<IProps>> = ({ children, text }) => {
    const [active, setActive] = useState(false);

    const onMouseEnter = useCallback(() => {
        setActive(true);
    }, [setActive]);

    const onMouseLeave = useCallback(() => {
        setActive(false);
    }, [setActive]);

    return (
        <TooltipWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
            <TooltipContent visible={active}>{text}</TooltipContent>
        </TooltipWrapper>
    );
};
