import * as React from "react";
import { AuthContext } from "./AuthContext";
import { CoderOneRoute } from "../Pages/CoderOneRoute";
import { useContext } from "react";
import { Redirect } from "@reach/router";
import { SSRSafeSuspense } from "../SSRSafeSuspense/SSRSafeSuspense";
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";
import { useHasMounted } from "../../hooks/useHasMounted";
import { Role } from "@coderone/library";
const Login = loadable(() => import("../PageSwitch/pages/Login/Login.page"));
const ActivateAccount = loadable(() => import("../PageSwitch/pages/ActivateAccount/ActivateAccount.page"));

interface IProps {
    readonly fallback?: JSX.Element;
    readonly adminOnly?: boolean;
}

export const WithAuth: React.FC<React.PropsWithChildren<IProps>> = ({ children, fallback, adminOnly = false }) => {
    const { payload } = useContext(AuthContext);
    const [t] = useTranslation();
    const hasMounted = useHasMounted();
    if (!hasMounted) {
        return null;
    }
    if (payload === null) {
        return <SSRSafeSuspense fallback={<div>{t("loading")}</div>}>{fallback ?? <Login />}</SSRSafeSuspense>;
    }
    if (payload.shouldActivate === true) {
        return <SSRSafeSuspense fallback={<div>{t("loading")}</div>}>{<ActivateAccount />}</SSRSafeSuspense>;
    }
    const isNotAdmin = payload.roles.indexOf(Role.Admin) === -1;
    if (adminOnly && isNotAdmin) {
        return <Redirect from="*" to={CoderOneRoute.Home} noThrow />;
    }
    return <React.Fragment>{children}</React.Fragment>;
};
