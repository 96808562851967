import * as React from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { SubmissionsContent } from "./SubmissionsContent";

export const Submissions: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("submissions");
    const description = t("submissions");
    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={t("submissions")}>
                <SubmissionsContent />
            </AuthenticatedFrame>
        </WithAuth>
    );
};
